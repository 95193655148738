import React from 'react';
import AvatarData from './AvatarData';
import TeamData from './TeamData';
import { Container, Icon } from 'semantic-ui-react';
import {thousands_separators } from './Utils'

export default class PlayerCard extends React.Component {
   
    render(){
        return(
            <Container id="Header">
                <div className="PlayerCard">
                    <div className="GridItem_img">
                        <img src={AvatarData[this.props.data.CurrentPlayer.id_avatar].img} alt={AvatarData[this.props.data.CurrentPlayer.id_avatar].title} id="ImgAutoFit"/>
                    </div>
                    <div className="GridItem_info">
                        <p><b>{this.props.data.CurrentPlayer.name}</b><br/>
                        <Icon><img src={TeamData[this.props.data.CurrentPlayer.id_team].img} id="ImgAutoFit"  alt=''/></Icon>{this.props.data.CurrentPlayer.nameTeam}<br/>
                        <Icon><img src='/points.png' id="ImgAutoFit"  alt=''/></Icon><b>{thousands_separators(this.props.data.CurrentPlayer.score)} points</b></p>
                    </div>
                </div>
            </Container>
        )
    }
}
