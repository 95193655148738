const avatarData = [
    {
      img: "/Pictos SNCF/1.png",
      title: 'Avatar 1',
    },
    {
      img: "/Pictos SNCF/2.png",
      title: 'Avatar 2',
    },
    {
      img: "/Pictos SNCF/3.png",
      title: 'Avatar 3',
    },
    {
      img: "/Pictos SNCF/4.png",
      title: 'Avatar 4',
    },
    {
      img: "/Pictos SNCF/5.png",
      title: 'Avatar 5',
    },
    {
      img: "/Pictos SNCF/6.png",
      title: 'Avatar 6',
    },
    {
      img: "/Pictos SNCF/7.png",
      title: 'Avatar 7',
    },
    {
      img: "/Pictos SNCF/8.png",
      title: 'Avatar 8',
    },
    {
      img: "/Pictos SNCF/9.png",
      title: 'Avatar 9',
    },
    {
      img: "/Pictos SNCF/10.png",
      title: 'Avatar 10',
    },
    {
      img: "/Pictos SNCF/11.png",
      title: 'Avatar 11',
    },
  ];
  
  export default avatarData;