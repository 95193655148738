import React from 'react';
import { Container, Menu } from 'semantic-ui-react'

export default class NavBar extends React.Component {
  constructor(props){
    super(props)
    this.state = { activeItem: 'Hunt' }
  }

  handleScreenChange = (e, { name }) => 
  {
    this.setState({ activeItem: name })
    this.props.onChangeScreen(name)
  }

  
  render() {
    const { activeItem } = this.state
    return(
    <Container id="NavBar">
    <Menu fluid secondary icon='labeled' widths={3} id="MenuNavbar">
        <Menu.Item id="ButtonNavBarLeft"
          name='Profile'
          active={activeItem === 'Profile'}
          onClick={this.handleScreenChange}>
          <img src='/moi.png' alt='Icone' id="ImgNavBar"/>
          <p>Moi</p>
        </Menu.Item>

        <Menu.Item id="BigButtonNavBar"
          name='Hunt'
          active={activeItem === 'Hunt'}
          onClick={this.handleScreenChange}
        >
          <img src='/chasse.png' alt='Icone' id="BigImgNavBar"/>
          <p>Chasse</p>
        </Menu.Item>

        <Menu.Item id="ButtonNavBarRight"
          name='Score'
          active={activeItem === 'Score'}
          onClick={this.handleScreenChange}>
          <img src='/score.png' alt='Icone' id="ImgNavBar"/>
          <p>Score</p>
        </Menu.Item>
      </Menu>
    </Container>
    )
  }
}
