import React from 'react';
import Login from './Login';
import NavScreen from './NavScreen';
import NavBar from './NavBar';
import PlayerCard from './PlayerCard';
import {Icon} from 'semantic-ui-react';
import PopupSecurity from './PopupSecurity';

export default class Navigator extends React.Component {
  constructor(props){
    super(props)
    this.ChangeScreen = this.ChangeScreen.bind(this)
    this.SetPlayerLogged = this.SetPlayerLogged.bind(this)
    this.state = {
      isLoading : true,
      hasCampaign : false,
      isLogged : false,
      selected : 'Hunt'
    }
  }

  componentDidMount()
  {
    if(this.props.data.CurrentPlayer.id !== 0)
    {
      this.SetPlayerInfo(this.props.data.CurrentPlayer.id);
    }
    else
      this.setState({isLogged : false})
    this.CheckCampaignAvailable();
  }

  render() {
    if(this.state.isLoading) {
      return (
        <div className='Overlay'>
          <div>
            <p>Chargement...</p>
          </div>
        </div>
      )
    }
    const { detect } = require('detect-browser');
    const browser = detect();
    switch (browser && browser.name) {
    case 'chrome':
      console.log("Supported");
      if(this.state.hasCampaign) {
        if(this.state.isLogged) {
          return (
            <>
            <PlayerCard data={this.props.data}/>
            <NavScreen screen={this.state.selected} data={this.props.data}/>
            <NavBar onChangeScreen={this.ChangeScreen}/>
            <PopupSecurity data={this.props.data}/>
            </>
          )
        } else {
          return (
            <div className="LoginDiv">
              <Login onLogin={this.SetPlayerLogged} campaign={this.campaign}/>
            </div>
          )
        }
      } else {
        return (
          <div className="LoginDiv">
            <div className='Form'>
            <img src='/chasse.png' alt='' className='img_medium'/><br/>
              <h2 id="Uppercase">Chasse aux monstres</h2>
              <Icon color='red' name='exclamation triangle' size='huge'/>
              <br/>
              <br/>
             <p>Aucune campagne disponible.</p>
             </div>
          </div>
        )
      }
      break;
    default:
      return (
      <div className="LoginDiv">
            <div className='Form'>
            <img src='/chasse.png' alt='' className='img_medium'/><br/>
              <h2 id="Uppercase">Chasse aux monstres</h2>
             <Icon color='red' name='exclamation triangle' size='huge'/>
             <br/>
             <br/>
             <p>Ce site n'est pas compatible avec votre navigateur.<br/>
             Veuillez le lancer avec le navigateur "Chrome"</p>
             </div>
          </div>
      )
      console.log('not supported');
    }
  }

  ChangeScreen(value) {
    this.setState({selected : value})
  }

  SetPlayerInfo(playerId) {
    fetch('https://imrsivo.com/ARProjectSncf/Php/players?id=' + playerId,{
        method: 'GET'
    }).then(res => { if (res.ok) {
      res.json().then( json => {
        if(json && json.length > 0)
        {
          this.props.data.SetPlayer(json[0])
          this.setState({isLogged: true})
        }
        else
        {
          this.props.data.CurrentPlayer.id = 0
          this.setState({IsLogged: false})
        }
      })
    }})
  }

  SetPlayerLogged(playerId) {
    this.props.data.CurrentPlayer.id = playerId;
    this.SetPlayerInfo(playerId)
  }

  CheckCampaignAvailable()
  {
    fetch('https://imrsivo.com/ARProjectSncf/Php/campaigns?today=true',{
      method: 'GET'
    }).then(res => { if (res.ok) {
      res.json().then( json => {
        if(json && json.length > 0)
        {
          this.setState({hasCampaign : true})
          this.campaign = json[0]
          if(this.campaign.id !== this.props.data.CurrentPlayer.id_campaign)
          {
            this.props.data.DeletePlayer();
            this.setState({isLogged : false})
          }
        } else {
          this.setState({hasCampaign : false})
          this.props.data.DeletePlayer();
        }
      }).finally(() => {
        this.setState({isLoading: false})
      })
    }})
  }
}
