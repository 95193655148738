import React from 'react';
import GridList from './GridList'
import TeamSelection from './TeamSelection'
import TeamData from './TeamData'

export default class Login extends React.Component {
  constructor(props) {
    super(props)
    this.StepManager = this.StepManager.bind(this)
    this.SetAvatar = this.SetAvatar.bind(this)
    this.state = {
      step : 1,
      teamList : [],
      error : {
        teamPwd : false,
        addPlayer : false
      },
      validName : false,
      validPwd : false,
      selectedTeam : 1,
      password: '',
      username: '',
    }
    this.avatar = -1
    this.playerStatus = 0
  }

  componentDidMount(){
    fetch('https://imrsivo.com/ARProjectSncf/Php/teams',{
      method: 'GET'
    }).then(res => { if (res.ok) {
      res.json().then( json => {
        let teams = []
        for(let item of json){
          teams.push({id : item['id'], value : item['name']})
        }
        this.setState({teamList: teams})
      })
    }})
  }

  render() {
    return(
      <>
        {this.DisplayError()}
        {this.StepManager()}
        </>
    )
  }

  handleSelectedTeam = selection => {
    this.setState({ selectedTeam: selection });
  };

  StepManager(value = null) {
      switch(this.state.step){
          default:
          case 1: return (
            <div className='Form'>
              <img src='/chasse.png' alt='' className='img_medium'/><br/>
              <h2 id="Uppercase">Chasse aux monstres</h2>
              <p><b>{this.props.campaign.name}</b></p>
              <p>Bienvenue à toi Chasseur de Monstres !</p>
              <p style={{margin:'5%'}}> Ta gare est actuellement à la merci de puissants monstres.<br/>Brave les dangers qui se dressent devant toi et capture les différents monstres afin d’assurer la sécurité de ta gare ! 
              </p>
              <img src='/bienvenue.png' alt='' className='img_medium'/><br/>
              <button style={{paddingLeft:'20px', paddingRight:'20px'}} onClick={() => this.setState({step:2})}>Suivant</button>
            </div>
          )
          case 2:
            return (
            <div className='Form'>
              <img src={TeamData[this.state.selectedTeam].img} alt='Icone' className='img_big'/>
              <TeamSelection teamList={this.state.teamList} selectedTeam={this.state.selectedTeam} handleSelectedTeam={this.handleSelectedTeam} />    
              <h1>Saisissez le mot de passe</h1>
              <input type='password' id='password' value={this.state.password} onChange={(e) => this.CheckInputPwd(e.target.value)}/>
              <button onClick={() => this.CheckPassword()} disabled={!this.state.validPwd}>Connexion</button><br/>
              {/* <button className="debug" onClick={() => { this.setState({selectedTeam : 1, password: "Mercure"}); this.CheckPassword()}}>Debug auto log</button> */}
            </div>
          )
          case 3: return (
            <div className='Form'>
              <h1>Equipe : {this.state.teamList[this.state.selectedTeam - 1].value}</h1>
              <img src={TeamData[this.state.selectedTeam].img} alt='Icone' className='img_big'/>
              <h1>Entrez votre pseudo (minimum 3 caractères)</h1>
              <input id='username' value={this.state.username} onChange={(e) => this.CheckInputName(e.target.value)}/>
              <h1>Sélectionnez un avatar</h1>
              <GridList changeAvatar={this.SetAvatar}/>
              <div className='ButtonList'>
                <button onClick={() => {this.setState({step:2, password : ''})}}>Précédent</button>
                <button onClick={() => this.AddPlayer()} disabled={!this.state.validName}>C'est parti !</button>
              </div>
            </div>
          )
      }
  }

  SetAvatar(value) {
    this.avatar = value;
    if(this.state.username && this.state.username.length >= 3 && this.avatar > -1)
      this.setState({validName : true});
    else
      this.setState({validName : false});
  }

  CheckPassword () {
    fetch('https://imrsivo.com/ARProjectSncf/Php/checkAuthen?id=' + this.state.selectedTeam + '&pwd=' + this.state.password,{
      method: 'GET'
    }).then(res => {if (res.ok) {
      res.json().then( json => {
        if(json)
        {
          this.setState({step : 3, validName : false, username : ''})
          this.avatar = -1;
        } else {
          this.setState({error : { teamPwd : true }})
        }
      })
    }/* else {erreur serveur }*/})
  }

  AddPlayer () {
    var formdata = new FormData();
    formdata.append("name", this.state.username);
    formdata.append("avatar", this.avatar);
    formdata.append("team", this.state.selectedTeam);
    fetch('https://imrsivo.com/ARProjectSncf/Php/players',{
      method: 'POST',
      body: formdata
    }).then(res => {if (res.ok) {
      res.json().then(json => {
        if(json['status'] > 0)
        {
          this.props.onLogin(json['data'])
        }
        else
        {
          this.playerStatus = json['status']
          this.setState({error : { addPlayer : true }})
        }
      })
    }})
  }

  AddPlayerError()
  {
    switch (this.playerStatus)
    {
      case -1: return "Aucune campagne aujourd'hui"
      case -2: return "Ce pseudo est déjà utilisé"
      case -3: return "Aucune équipe existante"
      case -4: return "Erreur interne"
      default: return "Erreur inconnue"
    }
  }

  DisplayError()
  {
    if(this.state.error.teamPwd)
    {
      return (
        <div className='Overlay'>
          <div>
            <p>Mot de passe incorrect</p>
            <button onClick={() => this.setState({error:{teamPwd: false}})}>Ok</button>
          </div>
        </div>
      )
    }
    if(this.state.error.addPlayer)
    {
      return (
        <div className='Overlay'>
          <div>
            <p>{this.AddPlayerError()}</p>
            <button onClick={() => this.setState({error:{addPlayer: false}})}>Ok</button>
          </div>
        </div>
      )
    }
  }

  CheckInputName(value)
  {
    if(value && value.length >= 3 && this.avatar > -1)
      this.setState({validName : true, username : value});
    else
      this.setState({validName : false, username : value});
  }

  CheckInputPwd(value)
  {
    if(value)
      this.setState({validPwd : true, password : value});
    else
      this.setState({validPwd : false, password : value});
  }
}
