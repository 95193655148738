import React from 'react';
import AvatarData from './AvatarData';

export default class GridList extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      selected: null
    }
  }
  render(){
    return (
      <div className='materialGridList'>
        {AvatarData.map((tile,key) => (
          <img onClick={() => this.ChangeAvatar(key)} key={key} src={tile.img} alt={tile.title} style={this.Highlight(key)} />
        ))}
      </div>
    );
  }
  
  Highlight(value) {
    if(this.state.selected === value){
      return ({border:'2px solid green'})
    }
  }
  ChangeAvatar(value) {
    this.setState({selected:value});
    this.props.changeAvatar(value);
  }
}
