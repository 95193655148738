class Store {
  constructor(){
    this.SetPlayer = this.SetPlayer.bind(this)
    let player = JSON.parse(localStorage.getItem('playerInfo'))

    if(player !== null)
    {
      this.CurrentPlayer = {...this.CurrentPlayer,...player}
    }
    if(!this.CheckCampaign())
    {
      localStorage.removeItem('playerInfo')
      this.CurrentPlayer.id = 0
    }
  }

  CurrentPlayer = {
    id: 0,
    name:"",
    nb_monster_1:0,
    nb_monster_2:0,
    nb_monster_3:0,
    id_avatar:0,
    id_team:0,
    nameTeam:"",
    id_campaign:0,
    nameCampaign:"",
    date_start:"",
    date_end:"",
    score: 0,
    last_hunt : 0,
    diffScoring : 0
  }

  DeletePlayer()
  {
    localStorage.removeItem('playerInfo')
  }
  
  SetPlayer = player => {
    let old = this.CurrentPlayer;
    if(old && player.id === old.id)
    {
      this.CurrentPlayer.diffScoring = player.score - old.score;
      if(player.nb_monster_1 !== old.nb_monster_1)
      {
        this.CurrentPlayer.last_monster = "Loup"
      }
      else if(player.nb_monster_2 !== old.nb_monster_2)
      {
        this.CurrentPlayer.last_monster = "Dragon"
      }
      else if(player.nb_monster_3 !== old.nb_monster_3)
      {
        this.CurrentPlayer.last_monster = "Phoenix"
      }
    }
    else
    {
      this.CurrentPlayer.last_hunt = 0;
      this.CurrentPlayer.diffScoring = 0;
    }
    this.CurrentPlayer = {...old,...player}
    localStorage.setItem('playerInfo', JSON.stringify(this.CurrentPlayer));
  }

  SetDiffScoring(value)
  {
    this.CurrentPlayer.diffScoring = value;
    localStorage.setItem('playerInfo', JSON.stringify(this.CurrentPlayer));
  }

  SetLastHunt(value)
  {
    this.CurrentPlayer.last_hunt = value;
    localStorage.setItem('playerInfo', JSON.stringify(this.CurrentPlayer));
  }

  CheckCampaign()
  {
    let cp = this.CurrentPlayer
    if (cp.date_start === "" || cp.date_end === "")
      return false
    let start = new Date(cp.date_start)
    let end = new Date(cp.date_end)
    let current = new Date()
    end.setDate(end.getDate() + 1);
    return (start < current && current < end)
  }
}

const DataStore = new Store();
export default DataStore;
