import React from 'react';

export default class ScreenMore extends React.Component {
  render(){
    return(
      <div className="ScreenMore">
        Page Plus
      </div>
    )
  }
}
