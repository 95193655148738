import React from 'react';
import LeaderboardTeam from './LeaderboardTeam';
import LeaderboardPlayer from './LeaderboardPlayer';
import { Button } from 'semantic-ui-react'

export default class ScreenScore extends React.Component {
  constructor(props) {
    super(props)
    this.StepManager = this.StepManager.bind(this)
    this.state = {
      step : 1,
      isPlayerLoaded : false,
      isTeamLoaded : false,
    }
  }

  componentDidMount()
  {
    fetch('https://imrsivo.com/ARProjectSncf/Php/leaderboard',{
        method: 'GET'
    }).then(res => { if (res.ok) {
      res.json().then( json => {
        if(json)
        {
          this.players = json;
          this.setState({isPlayerLoaded : true})
        }
      })
    }})
    fetch('https://imrsivo.com/ARProjectSncf/Php/leaderboard?type=team',{
        method: 'GET'
    }).then(res => { if (res.ok) {
      res.json().then( json => {
        if(json)
        {
          this.teams = json;
          this.setState({isTeamLoaded : true})
        }
      })
    }})
  }

  StepManager(value = null) {
    if(this.state.isPlayerLoaded === false || this.state.isTeamLoaded === false)
    {
      return (
        <div>
          <p>Chargement...</p>
        </div>
      )
    }
    else
    {
      switch(this.state.step){
        default:
        case 1: 
        return (
          <div className="divLeaderboard">
            <LeaderboardPlayer data={this.players} playerId={this.props.data.CurrentPlayer.id}/>
          </div>
        )
        case 2:
          return (
            <div className="divLeaderboard">
              <LeaderboardTeam data={this.teams} teamId={this.props.data.CurrentPlayer.id_team}/>
          </div>
        )
    }
    }
  }

  render(){
    return(
      <div className="ScreenScore">
        <h1>Classement</h1>
        <div className="ScoreBar">
          <Button id='button' attached='left' disabled={this.state.step === 1 ? true : false } onClick={() => this.setState({step:1})}>Joueurs</Button>
          <Button id='button' attached='right' disabled={this.state.step === 2 ? true : false }  onClick={() => this.setState({step:2})}>Equipes</Button>
        </div>
        {this.StepManager()}
      </div>
    )
  }
}
