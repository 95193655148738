import React, { Fragment } from "react";
import {
  Modal as ModalComponent,
  Icon, 
  TransitionablePortal
} from "semantic-ui-react";



export default class PopupSecurity extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = { open : this.OpenPopup()}
        this.transition = {
          animation: "fade",
          duration: 500
      } 
    }

  OpenPopup()
  {
    var parts = window.location.pathname.split('/');
    var lastSegment = parts.pop() || parts.pop();
    if(this.props.data.CurrentPlayer.diffScoring !== 0 || lastSegment === "Back")
      return false;
    return true;
  }

  setOpen = open => {
    this.setState((prevState, props) => ({
      open
    }));
  };

  handleTriggerClick = event => {
    this.setOpen(true);
  };

  handleClose = (event, data) => {
    this.props.data.SetDiffScoring(0);
    this.setOpen(false);
  };

  render() {
    const { open } = this.state;
    const { transition } = this.transition;
    
    return (
      <Fragment>
        <TransitionablePortal {...{ open }} {...{ transition }}>
          <ModalComponent
        open
        onClose={this.handleClose}
        size='fullscreen'
        closeIcon>
    <ModalComponent.Header id="ModalHeader">
    <Icon color='yellow' name='exclamation triangle'/>
      Consignes de sécurité
      </ModalComponent.Header>
        <ModalComponent.Content scrolling>
        <p style={{textAlign:'justify', margin:'1%', textJustify:'inter-word', marginTop:'5%'}}><Icon name="caret right"/>Veillez à ne pas utiliser votre smartphone lors de vos déplacements en gare.</p>
        <p style={{textAlign:'justify', margin:'1%', textJustify:'inter-word', marginTop:'5%'}}><Icon name="caret right"/>Soyez vigilants lorsque vous empruntez les escaliers.</p>
        <p style={{textAlign:'justify', margin:'1%', textJustify:'inter-word', marginTop:'5%'}}><Icon name="caret right"/>Veillez à ne pas gêner le flux des voyageurs en gare.</p>
        <p style={{textAlign:'justify', margin:'1%', textJustify:'inter-word', marginTop:'5%'}}><Icon name="caret right"/>Ne courrez pas.</p>
    </ModalComponent.Content>
    <ModalComponent.Actions id="ModalAction">
      <button onClick={this.handleClose}>Fermer</button>
    </ModalComponent.Actions>
        </ModalComponent>
        </TransitionablePortal>
      </Fragment>
    );
  }
}
