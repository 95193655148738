import React from 'react';
import AvatarData from './AvatarData';
import TeamData from './TeamData';
import { Icon } from 'semantic-ui-react';
import { thousands_separators } from './Utils';

export default class LeaderboardPlayer extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            data : this.props.data,
            expandedRows : []
        };
    }

    handleRowClick(rowId) {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
        
        const newExpandedRows = isRowCurrentlyExpanded ? 
			currentExpandedRows.filter(id => id !== rowId) : 
			currentExpandedRows.concat(rowId);
        
        this.setState({expandedRows : newExpandedRows});
    }

    renderItem(item) {
        const clickCallback = () => this.handleRowClick(item.id);
        var itemRows;
        var srcChevron = "/chevron_down.png"
        if(this.state.expandedRows.includes(item.id))
            srcChevron = "/chevron_up.png"
        if(item.id === this.props.playerId)
        {
            itemRows = [
                <tr key={"row-data-" + item.id} className='user_score'>
                    <td><b>{item.rank}</b></td>
                    <td><img src={AvatarData[item.idAvatar].img} alt=''/></td>
                    <td colSpan="2" style={{textAlign: "left"}}>
                    <p><b>{item.playerName}</b><br/>
                    <Icon><img src={TeamData[item.idTeam].img} id="ImgAutoFit" alt=''/></Icon>{item.teamName}<br/>
                    <Icon><img src='/points.png' id="ImgAutoFit" alt=''/></Icon><b>{thousands_separators(item.score)} points</b></p>
                    </td>
                    <td>
                        <li className='chevron' onClick={clickCallback}>
                            <img src={srcChevron} alt='Details'/>
                        </li>
                    </td>
                </tr>];
        }
        else
        {
            itemRows = [
                <tr key={"row-data-" + item.id}>
                    <td><b>{item.rank}</b></td>
                    <td><img src={AvatarData[item.idAvatar].img} alt=''/></td>
                    <td colSpan="2" style={{textAlign: "left"}}>
                      <p><b>{item.playerName}</b><br/>
                      <Icon><img src={TeamData[item.idTeam].img} id="ImgAutoFit" alt=''/></Icon>{item.teamName}<br/>
                      <Icon><img src='/points.png' id="ImgAutoFit" alt=''/></Icon><b>{thousands_separators(item.score)} points</b></p>
                    </td>
                    <td>
                        <li className='chevron' onClick={clickCallback}>
                            <img src={srcChevron} alt='Details'/>
                        </li>
                    </td>				
                </tr>];
        }
        if(this.state.expandedRows.includes(item.id)) {
            itemRows.push(
                <tr key={"row-expanded-" + item.id} id="LeaderboardDetail">
                    <td></td>
                    <td>
                    <p><b>Loup</b></p>
                    <img src='/Pictos Monstres/Loup.png' alt='' className='img_small'/>
                    <p>{item.nb_monster_1}</p>
                    </td>
                    <td>
                    <p><b>Dragon</b></p>
                    <img src='/Pictos Monstres/Dragon.png' alt='' className='img_small'/>
                    <p>{item.nb_monster_2}</p>
                    </td>
                    <td>
                    <p><b>Phoenix</b></p>
                    <img src='/Pictos Monstres/Phoenix.png' alt='' className='img_small'/>
                    <p>{item.nb_monster_3}</p>
                    </td>
                    <td></td>
                </tr>
            );
        }
        
        return itemRows;    
    }
    
    render() {
        let allItemRows = [];
        this.state.data.forEach(item => {
            const perItemRows = this.renderItem(item);
            allItemRows = allItemRows.concat(perItemRows);
        });
        
        return (
            <div className='Leaderboard'>
			    <table>
                    <tbody>
                         {allItemRows}
                    </tbody>
                </table>
            </div>
        );
    }
}