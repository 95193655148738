import React from 'react';
import ScreenProfile from './ScreenProfile';
import ScreenHunt from './ScreenHunt';
import ScreenScore from './ScreenScore';
import ScreenMore from './ScreenMore';
import {Container} from 'semantic-ui-react';


const NavScreen = ({screen, data}) => {
  function displayScreen(value) {
    switch(value){
      case 'Profile' : return <ScreenProfile data={data}/>
      case 'Hunt' : return <ScreenHunt data={data}/>
      case 'Score' : return <ScreenScore data={data}/>
      case 'More' : return <ScreenMore/>
      default : return <div>Error</div>
    }
  }
  return (
    <Container id="Content">
      {displayScreen(screen)}
    </Container>
  )
}

export default NavScreen;
