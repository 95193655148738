import React from "react";

export default class TeamSelection extends React.Component {
  render() {
    let options = this.props.teamList.map((array) => <option key={array.id} value={array.id}>{array.value}</option>)
    return (
      <div className="TeamSelection">
          <h1>Sélectionnez votre équipe</h1>
              <select id='team' value={this.props.selectedTeam}
            onChange={(e) => {
              this.props.handleSelectedTeam(e.target.value);
            }}>
                {options}
              </select>
      </div>
    );
  }
}