const TeamData = [
      {
        img: "/logo192.png",
        title: 'Default',
      },
      {
        img: "/Pictos Team/Risorsa 1.png",
        title: 'Team 1',
      },
      {
        img: "/Pictos Team/Risorsa 3.png",
        title: 'Team 2',
      },
      {
        img: "/Pictos Team/Risorsa 4.png",
        title: 'Team 3',
      },
      {
        img: "/Pictos Team/Risorsa 5.png",
        title: 'Team 4',
      },
      {
        img: "/Pictos Team/Risorsa 6.png",
        title: 'Team 5',
      },
      {
        img: "/Pictos Team/Risorsa 7.png",
        title: 'Team 6',
      },
      {
        img: "/Pictos Team/Risorsa 9.png",
        title: 'Team 7',
      },
      {
        img: "/Pictos Team/Risorsa 10.png",
        title: 'Team 8',
      },
      {
        img: "/Pictos Team/Risorsa 11.png",
        title: 'Team 9',
      },
      {
        img: "/Pictos Team/Risorsa 12.png",
        title: 'Team 10',
      },
      {
        img: "/Pictos Team/Risorsa 13.png",
        title: 'Team 11',
      },
      {
        img: "/Pictos Team/Risorsa 14.png",
        title: 'Team 12',
      },
      {
        img: "/Pictos Team/Risorsa 16.png",
        title: 'Team 13',
      },
      {
        img: "/Pictos Team/Risorsa 17.png",
        title: 'Team 14',
      },
      {
        img: "/Pictos Team/Risorsa 18.png",
        title: 'Team 15',
      },
      {
        img: "/Pictos Team/Risorsa 19.png",
        title: 'Team 16',
      },
      {
        img: "/Pictos Team/Risorsa 20.png",
        title: 'Team 17',
      },
      {
        img: "/Pictos Team/Risorsa 21.png",
        title: 'Team 18',
      },
      {
        img: "/Pictos Team/Risorsa 22.png",
        title: 'Team 19',
      },
      {
        img: "/Pictos Team/Risorsa 23.png",
        title: 'Team 20',
      },
      {
        img: "/Pictos Team/Risorsa 24.png",
        title: 'Team 21',
      },
      {
        img: "/Pictos Team/Risorsa 25.png",
        title: 'Team 22',
      },
      {
        img: "/Pictos Team/Risorsa 26.png",
        title: 'Team 23',
      },
      {
        img: "/Pictos Team/Risorsa 27.png",
        title: 'Team 24',
      },

  ];
  
  export default TeamData;