import React, { Fragment } from "react";
import {
  Modal as ModalComponent,
  Icon, 
  TransitionablePortal
} from "semantic-ui-react";
import AvatarData from './AvatarData';
import TeamData from './TeamData';
import { thousands_separators } from "./Utils";


export default class TeamDetail extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = { open : this.props.open}
        this.transition = {
            animation: "fly down",
            duration: 400
        }
    }

  setOpen = open => {
    this.setState((prevState, props) => ({
      open
    }));
  };

  handleTriggerClick = event => {
    this.setOpen(true);
  };

  handleClose = (event, data) => {
    this.setOpen(false);
    this.props.onClose();
  };

  renderItem(item) {
    const itemRows = [
        <tr key={"row-data-" + item.playerName}>
            <td><img src={AvatarData[item.idAvatar].img} alt=''/></td>
            <td colSpan="4" style={{textAlign: "left"}}>
                <p><b>{item.playerName}</b><br/>
                <Icon><img src='/points.png' id="ImgAutoFit" alt=''/></Icon><b>{thousands_separators(item.score)} points</b></p>
            </td>		
        </tr>
    ];
    return itemRows;    
}

  render() {
    const { open } = this.state;
    const { transition } = this.transition;
    let allItemRows = [];
    if(this.props.players)
    {
        this.props.players.forEach(item => {
            const perItemRows = this.renderItem(item);
            allItemRows = allItemRows.concat(perItemRows);
        });  
    }
    return (
      <Fragment>
        <TransitionablePortal {...{ open }} {...{ transition }}>
          <ModalComponent
        open
        onClose={this.handleClose}
        size='fullscreen'
        closeIcon>
    <ModalComponent.Header id="ModalHeader">
      <Icon><img src={TeamData[this.props.id].img} id="ImgAutoFit"  alt=''/></Icon>
      {this.props.teamName}
      </ModalComponent.Header>
        <ModalComponent.Content scrolling id='TeamDetailList'>
        <h2><Icon><img src='/points.png' id="ImgAutoFit"  alt=''/></Icon>{thousands_separators(this.props.score)} points</h2>
        <p>Composition de l'équipe</p>
        <table>
            <tbody>
                {allItemRows}
            </tbody>
        </table>
    </ModalComponent.Content>
    <ModalComponent.Actions id="ModalAction">
      <button onClick={this.handleClose}>Fermer</button>
    </ModalComponent.Actions>
        </ModalComponent>
        </TransitionablePortal>
      </Fragment>
    );
  }
}