import React, { Component } from 'react'
import moment from 'moment'

export default class BtnHunt extends Component {
    constructor(props)
    {
        super(props)
        this.state = this.GetTimer()
    }

    GetTimer()
    {
        if(this.props.data.CurrentPlayer.last_hunt === 0)
            return {seconds : 0, minutes : 0}
        var nextHunt = moment(this.props.data.CurrentPlayer.last_hunt);
        nextHunt.add({minutes: 0, seconds: 30})
        var now = moment()
        if(now > nextHunt)
            return {seconds : 0, minutes : 0}
        else
        {
            var diff = moment.duration(nextHunt.diff(now))
            return {seconds: diff.seconds(), minutes : diff.minutes()}
        }
    }

    componentDidMount() {
        this.myInterval = setInterval(() => {
            const { seconds, minutes } = this.state

            if (seconds > 0) {
                this.setState(({ seconds }) => ({
                    seconds: seconds - 1
                }))
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(this.myInterval)
                } else {
                    this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59
                    }))
                }
            } 
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.myInterval)
    }

    render() {
        const { minutes, seconds } = this.state
        var secondsTxt = seconds
        if(seconds < 10)
            secondsTxt = `0${seconds}`;
        var timer = `${minutes}:${secondsTxt}`
        if(minutes === 0 && seconds === 0)
        {
            return (
                <>
                    <input id="inputBtn" type="submit" value="C'est parti !"/>
                </>
            )
        }
        else
        {
            return (
                <>
                    <input id="inputBtn" type="submit" value={timer} disabled/>
                </>
            )
        }
    }
}