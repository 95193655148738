import React from 'react';
import TeamData from './TeamData'
import {Grid} from "semantic-ui-react";
import { thousands_separators } from './Utils';

export default class ScreenProfile extends React.Component {
  render(){
    return(
      <div className="ScreenProfile">
        <h1>Profil détaillé</h1>
        <div className="Team">
          <img src= {TeamData[this.props.data.CurrentPlayer.id_team].img} alt='' className='img_medium'/>
          <h3 style={{margin:'0', marginBottom:'5%'}}>{this.props.data.CurrentPlayer.nameTeam}</h3>
        </div>
        <div className="GlobalScore">
          <img src='/points.png' alt='' className='img_big'/>
          <p><b>{thousands_separators(this.props.data.CurrentPlayer.score)} points</b></p>
        </div>
        <div className="DetailScore">
    <Grid columns='equal' id="DetailScoreGrid">
    <Grid.Column>
    <div className="MonsterKill">
      <p><b>Loup</b></p>
        <img src='/Pictos Monstres/Loup.png' alt='' className='img_little'/>
              <p>{this.props.data.CurrentPlayer.nb_monster_1}</p>
          </div>
    </Grid.Column>
    <Grid.Column>
    <div className="MonsterKill">
            <p><b>Dragon</b></p>
              <img src='/Pictos Monstres/Dragon.png' alt='' className='img_little'/>
              <p>{this.props.data.CurrentPlayer.nb_monster_2}</p>
            </div>
    </Grid.Column>
    <Grid.Column>
    <div className="MonsterKill">
      <p><b>Phoenix</b></p>
              <img src='/Pictos Monstres/Phoenix.png' alt='' className='img_little'/>
              <p>{this.props.data.CurrentPlayer.nb_monster_3}</p>
            </div>
    </Grid.Column>
  </Grid>
        </div>
      </div>
    )
  }
}
