import React, { Fragment } from "react";
import {
  Modal as ModalComponent,
  Icon, 
  TransitionablePortal
} from "semantic-ui-react";
import {thousands_separators } from './Utils'


export default class PopupScore extends React.Component {
    constructor(props)
    {
        super(props)
        this.state = { open : this.props.open}
        this.transition = {
            animation: "fly down",
            duration: 500
        } 
    }

  setOpen = open => {
    this.setState((prevState, props) => ({
      open
    }));
  };

  handleTriggerClick = event => {
    this.setOpen(true);
  };

  handleClose = (event, data) => {
    this.props.data.SetDiffScoring(0);
    this.setOpen(false);
  };

  render() {
    const { open } = this.state;
    const { transition } = this.transition;
    var monster = this.props.data.CurrentPlayer.last_monster;

    return (
      <Fragment>
        <TransitionablePortal {...{ open }} {...{ transition }}>
          <ModalComponent
        open
        onClose={this.handleClose}
        size='fullscreen'
        closeIcon>
    <ModalComponent.Header id="ModalHeader">
      <Icon><img src='/points.png' id="ImgAutoFit" alt=''/></Icon>
      Félicitation
      </ModalComponent.Header>
        <ModalComponent.Content scrolling>
      <p>
      Félicitations vous venez d’attraper un <b>{monster}</b>
      </p>
      <p>Vous avez gagné <b>{thousands_separators(this.props.diffScore)} points</b></p>
      <p>Votre total est maintenant de <b>{thousands_separators(this.props.newScore)} points</b></p>
    </ModalComponent.Content>
    <ModalComponent.Actions id="ModalAction">
      <button onClick={this.handleClose}>Fermer</button>
    </ModalComponent.Actions>
        </ModalComponent>
        </TransitionablePortal>
      </Fragment>
    );
  }
}
