import React from 'react';
import TeamData from './TeamData';
import TeamDetail from './TeamDetail';
import { Icon } from 'semantic-ui-react';
import { thousands_separators } from './Utils';

export default class LeaderboardTeam extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data : this.props.data,
            expandedRows : [],
            showPopup: false
        };
        this.togglePopup = this.togglePopup.bind(this);
    }

    togglePopup(players, idTeam, teamName, score) { 
        this.players = players;
        this.teamName = teamName;
        this.teamSelected = idTeam;
        this.score = score;
        this.setState({  
             showPopup: true  
        }); 
    }  

    handleRowClick(rowId) {
        const currentExpandedRows = this.state.expandedRows;
        const isRowCurrentlyExpanded = currentExpandedRows.includes(rowId);
        
        const newExpandedRows = isRowCurrentlyExpanded ? 
			currentExpandedRows.filter(id => id !== rowId) : 
			currentExpandedRows.concat(rowId);
        
        this.setState({expandedRows : newExpandedRows});
    }

    renderItem(item) {
        const clickCallback = () => this.handleRowClick(item.id);
        var itemRows;
        var srcChevron = "/chevron_down.png"
        if(this.state.expandedRows.includes(item.id))
            srcChevron = "/chevron_up.png"
        if(item.id === this.props.teamId)
        {
            itemRows = [
			    <tr key={"row-data-" + item.id} className='user_score'>
			        <td><b>{item.rank}</b></td>
			        <td id="tdImg">
                        <img src={TeamData[item.id].img} onClick= { () => {this.togglePopup(item.players, item.id, item.teamName, item.score)}}  alt=''/>
                        <img id="infoImg" src='./info.png' onClick= { () => {this.togglePopup(item.players, item.id, item.teamName, item.score)}} alt=''/>
                    </td>
                    <td colSpan="3" style={{textAlign: "left", paddingLeft: "5px"}}>
                    <p><b>{item.teamName}</b><br/>
                    <Icon><img src='/points.png' id="ImgAutoFit"  alt=''/></Icon><b>{thousands_separators(item.score)} points</b></p>
                    </td>
			        <td>
                        <li className='chevron' onClick={clickCallback}>
                            <img src={srcChevron} alt='Details'/>
                        </li>
                    </td>			
			    </tr>];
        }
        else
        {
            itemRows = [
                <tr key={"row-data-" + item.id}>
                    <td><b>{item.rank}</b></td>
                    <td id="tdImg">
                        <img src={TeamData[item.id].img} onClick= { () => {this.togglePopup(item.players, item.id, item.teamName, item.score)}}  alt=''/>
                        <img id="infoImg" src='./info.png' onClick= { () => {this.togglePopup(item.players, item.id, item.teamName, item.score)}} alt=''/>
                        </td>
                    <td colSpan="3" style={{textAlign: "left", paddingLeft: "5px"}}>
                        <p><b>{item.teamName}</b><br/>
                        <Icon><img src='/points.png' id="ImgAutoFit"  alt=''/></Icon><b>{thousands_separators(item.score)} points</b></p>
                    </td>
                    <td>
                        <li className='chevron' onClick={clickCallback}>
                            <img src={srcChevron} alt='Details'/>
                        </li>
                    </td>	
                </tr>];
        }
        if(this.state.expandedRows.includes(item.id)) {
            itemRows.push(
                <tr key={"row-expanded-" + item.id} id="LeaderboardDetail">
                    <td></td>
                    <td>
                        <p><b>Loup</b></p>
                        <img src='/Pictos Monstres/Loup.png' alt='' className='img_small'/>
                        <p>{item.nb_monster_1}</p>
                    </td>
                    <td colSpan="2">
                        <p><b>Dragon</b></p>
                        <img src='/Pictos Monstres/Dragon.png' alt='' className='img_small'/>
                        <p>{item.nb_monster_2}</p>
                    </td>
                    <td>
                        <p><b>Phoenix</b></p>
                        <img src='/Pictos Monstres/Phoenix.png' alt='' className='img_small'/>
                        <p>{item.nb_monster_3}</p>
                    </td>
                    <td></td>
                </tr>
            );
        }
        
        return itemRows;    
    }
    
    render() {
        let allItemRows = [];
        this.state.data.forEach(item => {
            const perItemRows = this.renderItem(item);
            allItemRows = allItemRows.concat(perItemRows);
        });
        
        return (
            <div className='Leaderboard'>
			    <table>
                    <tbody>
                         {allItemRows}
                    </tbody>
                </table>
                {this.state.showPopup ?
                <TeamDetail players={this.players}
                          id={this.teamSelected}
                          teamName={this.teamName}
                          score={this.score}
                          open={this.state.showPopup}
                          onClose={() => this.setState({showPopup : false})}
                          />
                 : null}
            </div>
        );
    }
}