import React from 'react';
import PopupScore from './PopupScore';
import BtnHunt from "./BtnHunt";
import moment from 'moment';
import {Icon} from 'semantic-ui-react';

export default class ScreenHunt extends React.Component {
  constructor(props) {
    super(props)
    var currentPlayer = this.props.data.CurrentPlayer;
    this.diffScoring = 0;
    if(currentPlayer.diffScoring !== 0)
    {
      this.diffScoring = currentPlayer.diffScoring;
      this.props.data.SetLastHunt(moment());
    }
  }

  render(){
      return(
        <div className="ScreenHunt">
          <div>
            <PopupScore diffScore={this.diffScoring} newScore={this.props.data.CurrentPlayer.score} open={(this.diffScoring !== 0) ? true: false} data={this.props.data}/>
            <h1>Chasse aux Monstres</h1>
            <p>Bienvenue, on part chasser des monstres ?</p>
            <p style={{textAlign:'justify', margin:'1%', textJustify:'inter-word', marginTop:'5%'}}><Icon name="caret right"/>Dans ce jeu à la fois individuel et par équipe, vous devrez approcher votre smartphone des flash codes dispersés sur certains équipements prioritaires dans l'ensemble de la gare.</p>
            <p style={{textAlign:'justify', margin:'1%', textJustify:'inter-word', marginTop:'5%'}}><Icon name="caret right"/>Vous avez pour objectif de capturer les monstres qui apparaîtront sur votre écran après avoir scanné les flash codes.</p>
            <p style={{textAlign:'justify', margin:'1%', textJustify:'inter-word', marginTop:'5%'}}><Icon name="caret right"/>Les monstres attrapés vous permettront de gagner de points et déterminer l’équipe gagnante et le grand vainqueur individuel.</p>
            <form action="https://imrsivo.com/ARJS/Ar.php" method="POST" id="form">
              <input type="hidden" id="idPlayer" name="idPlayer" value={this.props.data.CurrentPlayer.id}/>
              <input type="hidden" id="prevUrl" name="prevUrl" value={window.location.href}/>
              <br/>
              <BtnHunt data={this.props.data} />
            </form>
          </div>
        </div>
      )  
  }
}
