import React from 'react';
import Navigator from './cmp/Navigator';
import DataStore from './cmp/DataStore';
import './App.css'

function App() {
  return (
    <div className="App">
      <Navigator data={DataStore}/>
    </div>
  );
}

export default App;
